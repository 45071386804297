@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@700&family=Poppins:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,300;6..12,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jaldi&display=swap');

.fontNavbar {
    font-family: 'Playfair Display', serif;
}

.fontdeskripsiNavbar {
    font-family: 'Nunito Sans', sans-serif;
}

.fontJudulJumbotron {
    font-family: 'Poppins', sans-serif;
}

.fontDeskripsi {
    font-family: 'Raleway', sans-serif;
}

.fontTitle {
    font-family: 'Jaldi', sans-serif;
}
